<template>
  <svg viewBox="0 0 23 23" :width="size">
    <switch>
      <g>
        <path
          d="M14.9 7.1c-.2-.5-.5-.9-.9-1.3s-.8-.6-1.3-.9c-.5-.2-1-.3-1.5-.3s-1.1.1-1.5.3c-.5.2-.9.5-1.3.9-.4.3-.7.8-.9 1.3-.2.5-.3 1-.3 1.5s.1 1.1.3 1.5c.2.5.5.9.9 1.3s.8.6 1.3.9c1 .4 2.1.4 3.1 0 .5-.2.9-.5 1.3-.9s.6-.8.9-1.3c.2-.5.3-1 .3-1.5s-.2-1.1-.4-1.5zm-1.6 3.4-.2.2c-.1.1-.2.2-.4.2-.1 0-.3-.1-.4-.2L10.8 9c-.1-.1-.1-.2-.1-.4v-2c0-.3.2-.5.5-.5h.2c.3 0 .5.2.5.5v1.8l1.5 1.4c.1.2.1.5-.1.7z"
        />
        <path
          d="M15.4 6.8c-.2-.5-.6-1-1-1.4-.4-.4-.9-.8-1.4-1-1.1-.5-2.4-.5-3.5 0-.6.2-1.1.6-1.5 1-.4.4-.7.9-1 1.4-.2.6-.3 1.2-.3 1.8s.1 1.2.3 1.8c.2.5.6 1 1 1.4.4.4.9.7 1.4 1 .6.2 1.1.4 1.8.4.6 0 1.2-.1 1.8-.4.5-.2 1-.6 1.4-1 .4-.4.7-.9 1-1.4.2-.6.4-1.1.4-1.8s-.2-1.2-.4-1.8zm-.3 3.5c-.2.5-.5 1-.9 1.4-.4.4-.8.7-1.4.9-.5.2-1.1.3-1.7.3s-1.1-.1-1.7-.3c-.5-.2-1-.5-1.4-.9-.4-.4-.7-.8-.9-1.4-.1-.6-.2-1.1-.2-1.7 0-.6.1-1.1.4-1.7.2-.5.5-1 .9-1.4.4-.3.8-.6 1.4-.8.5-.2 1.1-.3 1.7-.3s1.1.1 1.7.3c.5.2 1 .5 1.4.9.4.4.7.8.9 1.4.2.5.3 1.1.3 1.7-.1.5-.2 1-.5 1.6z"
        />
        <path
          d="M15.1 6.9c-.2-.5-.5-1-.9-1.4-.4-.4-.8-.7-1.4-.9-.5-.2-1.1-.3-1.7-.3s-1.1.1-1.7.3c-.4.3-.8.6-1.2 1-.4.4-.7.8-.9 1.3-.3.6-.4 1.1-.4 1.7 0 .6.1 1.1.3 1.7.2.5.5 1 .9 1.4.5.3.9.6 1.5.8.5.2 1.1.3 1.7.3s1.1-.1 1.7-.3c.5-.2 1-.5 1.4-.9.4-.4.7-.8.9-1.4.2-.5.3-1.1.3-1.7-.1-.5-.2-1-.5-1.6zm-.2 3.2c-.2.5-.5.9-.9 1.3s-.8.6-1.3.9c-1 .4-2.1.4-3.1 0-.5-.2-.9-.5-1.3-.9s-.6-.8-.9-1.3c-.2-.5-.3-1-.3-1.5s.1-1.1.3-1.5c.3-.5.6-1 1-1.3.4-.4.8-.6 1.3-.9.5-.2 1-.3 1.5-.3s1.1.1 1.5.3c.5.2.9.5 1.3.9s.6.8.9 1.3c.2.5.3 1 .3 1.5s-.1 1.1-.3 1.5z"
        />
        <g>
          <path
            d="M21.4 12.6c-.3 0-.5.2-.5.5v1.2c0 .6-.5 1.2-1.1 1.2h-17c-.7 0-1.2-.6-1.2-1.2V8.5C1.5 8.2 1.3 8 1 8s-.5.2-.5.5v5.8c0 .5.2 1 .5 1.4 0 .1.1.1.1.2l.2.2c.1 0 .1.1.2.1 0 0 .1 0 .1.1.1 0 .1.1.2.1.2.1.3.1.5.2.2 0 .3.1.5.1h8v4.5H5.5c-.3 0-.6.2-.6.5s.3.5.6.5h11c.3 0 .6-.2.6-.5s-.3-.5-.6-.5h-4.7v-4.5h7.8c.3 0 .5 0 .8-.1.1 0 .2-.1.2-.1.1 0 .1-.1.2-.1 0 0 .1 0 .1-.1.1 0 .1-.1.2-.1.1-.1.2-.1.2-.2s.1-.1.1-.2c.3-.4.5-.9.5-1.4v-1.3c.1-.3-.2-.5-.5-.5z"
          />
          <path
            d="M16.6 22.7h-11c-.6 0-1.1-.4-1.1-1 0-.5.5-1 1.1-1h4.8v-3.6H2.8c-.2 0-.4 0-.6-.1-.2 0-.4-.1-.6-.2-.1 0-.2-.1-.2-.1s-.1 0-.1-.1l-.2-.2-.3-.3-.2-.2c-.4-.4-.6-.9-.6-1.5V8.5c0-.5.4-1 1-1 .5 0 1 .4 1 1v5.8c0 .4.4.8.8.8h17c.4 0 .7-.4.7-.8v-1.2c0-.5.4-1 1-1 .5 0 1 .4 1 1v1.2c0 .6-.2 1.2-.6 1.7l-.2.2-.3.3-.2.2s-.1 0-.1.1c-.1 0-.2.1-.2.1-.1 0-.2.1-.3.1-.3.1-.6.2-.9.2h-7.3v3.6h4.2c.6 0 1.1.4 1.1 1-.1.5-.5.9-1.1.9zm-11.1-1c-.1 0-.2.1-.2.1s.1.1.2.1h11c.1 0 .2-.1.2-.1s-.1-.1-.2-.1h-11zM1 8.4c-.1 0-.1.1 0 0l-.1 5.9c0 .4.1.8.4 1.1l.1.1.2.2.1.1h.1c.1.2.1.2.2.2.1.1.3.1.4.1h8.9V21l.1.5.1-.4v-4.9h8.3c.2 0 .4 0 .6-.1.1 0 .1 0 .2-.1.1 0 .1-.1.2-.1h.1l.1-.1.2-.2.1-.1c.2-.3.4-.7.4-1.1v-1.2c0-.1-.2-.1-.2 0v1.2c0 .9-.7 1.6-1.5 1.7H2.8c-.9 0-1.7-.8-1.7-1.7l-.1-6c.1.1 0 0 0 0z"
          />
        </g>
        <g>
          <path
            d="M21.5 1.4c0-.1-.1-.1-.1-.2l-.2-.2c-.1 0-.1-.1-.2-.1 0 0-.1 0-.1-.1-.1 0-.1-.1-.2-.1-.3-.1-.6-.2-1-.2H2.8c-.4 0-.7.1-1 .2-.1 0-.1.1-.2.1 0 0-.1 0-.1.1-.1 0-.1 0-.2.1s-.2.1-.2.2l-.2.2c-.3.4-.4.9-.4 1.4V4c0 .3.2.5.5.5s.5-.2.5-.5V2.8c0-.7.6-1.2 1.2-1.2h17c.6.1 1.1.6 1.1 1.2v5.8c0 .3.2.5.5.5s.5-.2.5-.5V2.8c.2-.5 0-1-.3-1.4z"
          />
          <path
            d="M21.4 9.6c-.5 0-1-.4-1-1V2.8c0-.4-.3-.7-.7-.8H2.8c-.5 0-.8.3-.8.8V4c0 .5-.4 1-1 1s-1-.4-1-1V2.8c0-.6.2-1.2.6-1.7 0-.1.1-.1.1-.2L1 .6l.2-.2.1-.1c.1 0 .2-.1.2-.1.4-.1.9-.2 1.3-.2h16.9c.4 0 .8.1 1.2.3.1 0 .2.1.2.1l.1.1.2.2.3.3s.1 0 .2.1c.4.5.6 1.1.6 1.7v5.8c-.1.6-.5 1-1.1 1zM2.8 1.1H19.9c.9.1 1.5.8 1.5 1.7v5.8c0 .1.2.1.2 0V2.8c0-.4-.1-.8-.4-1.1l-.1-.1-.2-.2-.1-.1h-.1c-.1 0-.1-.1-.2-.1-.3-.1-.5-.2-.8-.2H2.8c-.3-.1-.6 0-.8.1-.1 0-.1.1-.2.1h-.1l-.1.1c-.1.1-.1.2-.2.2l-.1.1C1 2 .9 2.4.9 2.8V4c0 .1.2.1.2 0V2.8c0-1 .7-1.7 1.7-1.7z"
          />
        </g>
      </g>
    </switch>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      default: 19
    }
  }
};
</script>
